<script setup>
import NavigationNew from "@/components/NavigationNew.vue";
import store from "@/store";
import { random } from "lodash-es";
import { onMounted, ref, computed, reactive, watch } from "vue";
import { DateTime } from "luxon";
import TermsAcceptModal from "../components/TermsAcceptModal.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";

onMounted(() => {
  loadDailyQuestion();
});

const loading = ref(false);

const avatarImage = (char) => {
  const list = {
    Amanirenas: require("@/assets/characters/society-amanirenas-avatar.png"),
    "Mary Anning": require("@/assets/characters/society-anning-avatar.png"),
    Ashoka: require("@/assets/characters/society-ashoka-avatar.png"),
    Blackbeard: require("@/assets/characters/society-blackbeard-avatar.png"),
    Copernicus: require("@/assets/characters/society-copernicus-avatar.png"),
    "Leonardo Da Vinci": require("@/assets/characters/society-davinci-avatar.png"),
    Epicurus: require("@/assets/characters/society-epicurus-avatar.png"),
    "Stephan Farffler": require("@/assets/characters/society-farffler-avatar.png"),
    "Harriet Tubman": require("@/assets/characters/society-tubman-avatar.png"),
    Hypatia: require("@/assets/characters/society-hypatia-avatar.png"),
    Ikkyu: require("@/assets/characters/society-ikkyu-avatar.png"),
    "Joan of Arc": require("@/assets/characters/society-joan-of-arc-avatar.png"),
    "Marie Curie": require("@/assets/characters/society-marie-curie-avatar.png"),
    "Queen Nanny": require("@/assets/characters/society-queen-nanny-avatar.png"),
    Nefertiti: require("@/assets/characters/society-nefertiti-avatar.png"),
    "Ng Mui": require("@/assets/characters/society-ng-mui-avatar.png"),
    "Public Unversal Friend": require("@/assets/characters/society-puf-avatar.png"),
    Sacagawea: require("@/assets/characters/society-sacagawea-avatar.png"),
    "Nikola Tesla": require("@/assets/characters/society-tesla-avatar.png"),
    Yasuke: require("@/assets/characters/society-yasuke-avatar.png"),
    "Yi Xing": require("@/assets/characters/society-yi-xing-avatar.png"),
    Zenobia: require("@/assets/characters/society-zenobia-avatar.png"),
  };

  return list[char] ?? require("@/assets/characters/feudal-nigel-avatar.png");
};
const user = computed(() => {
  return store.state.auth.user;
});

const answers = computed(() => {
  if (dailyTrivia.value?.response) {
    return dailyTrivia.value?.question?.choices?.map((choice) => {
      return {
        label: choice,
        answered: !!dailyTrivia.value.response.answers?.find((a) => a === choice),
        answered_correctly:
          dailyTrivia.value.response.answered_correctly &&
          choice === dailyTrivia.value.response.answers[0],
        answered_incorrectly:
          (dailyTrivia.value.response.answered_correctly &&
            !!dailyTrivia.value.response.answers?.find((a) => a === choice) &&
            choice !== dailyTrivia.value.response.answers[0]) ||
          (!dailyTrivia.value.response.answered_correctly &&
            !!dailyTrivia.value.response.answers?.find((a) => a === choice)),
      };
    });
  }

  return [];
});
// const formatNumber = (num) => {
//   return num.toLocaleString("en-US");
// };

// const formatDate = (date) => {
//   return date ? DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED) : null;
// };

const answerQuestion = async (answer) => {
  loading.value = true;
  let req = await store.dispatch("arcade/arcadeTriviaDailyAnswer", {
    id: dailyTrivia.value.question.id,
    answer,
  });

  if (req) {
    dailyTrivia.value = req;
  }

  loading.value = false;
};

const reportQuestion = async () => {
  loading.value = true;
  let req = await store.dispatch("arcade/arcadeTriviaDailyReport", {
    id: dailyTrivia.value.question.id,
  });
  loading.value = false;
};

let dailyTrivia = ref(null);

const loadDailyQuestion = async () => {
  loading.value = true;
  dailyTrivia.value = await store.dispatch("arcade/dailyTrivia");
  loading.value = false;
};
</script>

<template>
  <div
    class="h-full bg-cover flex items-center justify-center bg-top relative flex-col"
    :style="{
      'background-image': `url('${require('@/assets/backgrounds/hq-v1.jpg')}')`,
    }"
  >
    <NavigationNew mode="light" />

    <Breadcrumbs
      :links="[
        { routeName: 'Home', label: 'Home' },
        { routeName: 'Arcade', label: 'Arcade' },
        { label: 'Daily Trivia' },
      ]"
    />

    <div class="flex-grow flex items-center justify-center w-full relative z-20 h-full">
      <div v-if="!user">
        <router-link
          :to="{ name: 'Login', query: { from: encodeURI('/arcade/trivia') } }"
          class="btn btn-sm"
        >
          Login
        </router-link>
      </div>
      <div class="flex flex-col items-center justify-center relative w-full h-full" v-else>
        <div
          class="w-full h-full flex items-center justify-center relative overflow-hidden flex-col lg:flex-row"
        >
          <div class="relative w-full md:w-2/3">
            <!-- <div class="absolute" style="width: 200%; left: 50%; transform: translate(-50%, 0)"> -->
            <img
              :src="require('@/assets/trivia/soth-trivia-screen-clear.png')"
              class="w-full relative top-0 left-0"
            />
            <img
              :src="require('@/assets/trivia/soth-trivia-screen-table.png')"
              class="w-full absolute top-0 left-0"
            />
            <img
              :src="require('@/assets/trivia/soth-trivia-screen-machine.png')"
              class="w-full absolute top-0 left-0"
            />
            <div
              class="absolute top-3/5 left-1/3 right-1/3 bottom-1/4 flex flex-col items-center pb-4 justify-center overflow-hidden"
              v-if="dailyTrivia?.question"
            >
              <div class="w-2/5 flex items-center justify-center flex-col pt-4">
                <img :src="avatarImage(dailyTrivia.question.character)" class="w-3/5 md:w-4/5" />
                <div
                  class="text-black mt-1 font-brother font-bold text-xxs text-center"
                  v-if="dailyTrivia?.question"
                >
                  {{ dailyTrivia.question.character }}
                </div>
              </div>
            </div>
            <img
              :src="require('@/assets/trivia/soth-trivia-screen-overlay.png')"
              class="w-full absolute top-0 left-0"
            />

            <div
              class="absolute top-1/4 left-1/3 right-1/3 bottom-1/2 overflow-hidden pt-2"
              v-if="dailyTrivia?.question"
            >
              <div class="w-full h-full pb-12 overflow-y-auto overflow-x-hidden">
                <div class="font-maindisplay font-bold text-white text-center text-sm lg:text-2xl">
                  {{ dailyTrivia.question.question }}
                </div>
              </div>
            </div>
            <!-- </div> -->
          </div>
          <div class="p-8 flex-grow">
            <div
              class="flex flex-col bg-white rounded-lg box-shadow-std p-6 w-full"
              v-if="dailyTrivia?.question"
            >
              <div class="text-center text-xl font-bold">
                <div v-if="dailyTrivia?.response?.answered_correctly">
                  <div class="text-green-600 mb-2">
                    You got the right answer for {{ dailyTrivia.response.points }} points!
                  </div>
                  <div class="text-xs">Come back tomorrow and play again</div>
                </div>
                <div v-else>
                  <div v-if="dailyTrivia.response.answers?.length" class="">
                    <div class="text-red-600">Sorry, wrong answer!</div>
                    <div class="mt-2">Pick a different answer</div>
                  </div>
                  <div v-else>Pick an answer</div>
                </div>
              </div>
              <div
                v-for="(choice, c) in answers"
                :key="c"
                @click="
                  {
                    if (!dailyTrivia?.response?.answered_correctly && !choice.answered) {
                      answerQuestion(choice.label);
                    }
                  }
                "
                class="rounded-md py-2 px-4 mt-6 border-2 text-lg cursor-pointer text-center font-brother font-bold text-ch1blue"
                :class="{
                  'bg-ch1cream border-ch1blue':
                    !choice.answered && !dailyTrivia.response.answered_correctly,
                  'bg-gray-100 border-gray-300':
                    !choice.answered_correctly &&
                    !choice.answered_incorrectly &&
                    dailyTrivia.response.answered_correctly,
                  'bg-red-100 border-red-300': choice.answered_incorrectly,
                  'bg-green-100 border-green-400': choice.answered_correctly,
                }"
              >
                {{ choice.label }}
              </div>
              <div
                class="text-center text-sm text-red-700 mt-6 cursor-pointer"
                @click="reportQuestion"
              >
                Report a problem with this question
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <TermsAcceptModal />
</template>
